document.addEventListener("DOMContentLoaded", function(){
  var imJs = {
    m: function (e) {
        imJs.d();
        imJs.methods();
    },
    d: function (e) {
        this._window = $(window),
        this._document = $(document),
        this._body = $('body'),
        this._html = $('html')
    },
    methods: function (e) {
      imJs.metismenu();
      imJs.sideMenu();
      // imJs.searchOpton();
    },
    // metismenu
    metismenu:function(){
      $('#mobile-menu-active').metisMenu();
    },
      // side menu desktop
      sideMenu:function(){
      $(document).on('click', '.menu-btn', function () {
        $("#side-bar").addClass("show");
        $("#anywhere-home").addClass("bgshow");
      });
      $(document).on('click', '.close-icon-menu', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
      });
      $(document).on('click', '#anywhere-home', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
      });
      $(document).on('click', '.onepage .mainmenu li a', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
      });
    },
  }

  imJs.m();

});

